var exports = {};
exports = {
  MAX_LENGTH: 1024 * 64,
  // Digits
  CHAR_0: "0",

  /* 0 */
  CHAR_9: "9",

  /* 9 */
  // Alphabet chars.
  CHAR_UPPERCASE_A: "A",

  /* A */
  CHAR_LOWERCASE_A: "a",

  /* a */
  CHAR_UPPERCASE_Z: "Z",

  /* Z */
  CHAR_LOWERCASE_Z: "z",

  /* z */
  CHAR_LEFT_PARENTHESES: "(",

  /* ( */
  CHAR_RIGHT_PARENTHESES: ")",

  /* ) */
  CHAR_ASTERISK: "*",

  /* * */
  // Non-alphabetic chars.
  CHAR_AMPERSAND: "&",

  /* & */
  CHAR_AT: "@",

  /* @ */
  CHAR_BACKSLASH: "\\",

  /* \ */
  CHAR_BACKTICK: "`",

  /* ` */
  CHAR_CARRIAGE_RETURN: "\r",

  /* \r */
  CHAR_CIRCUMFLEX_ACCENT: "^",

  /* ^ */
  CHAR_COLON: ":",

  /* : */
  CHAR_COMMA: ",",

  /* , */
  CHAR_DOLLAR: "$",

  /* . */
  CHAR_DOT: ".",

  /* . */
  CHAR_DOUBLE_QUOTE: "\"",

  /* " */
  CHAR_EQUAL: "=",

  /* = */
  CHAR_EXCLAMATION_MARK: "!",

  /* ! */
  CHAR_FORM_FEED: "\f",

  /* \f */
  CHAR_FORWARD_SLASH: "/",

  /* / */
  CHAR_HASH: "#",

  /* # */
  CHAR_HYPHEN_MINUS: "-",

  /* - */
  CHAR_LEFT_ANGLE_BRACKET: "<",

  /* < */
  CHAR_LEFT_CURLY_BRACE: "{",

  /* { */
  CHAR_LEFT_SQUARE_BRACKET: "[",

  /* [ */
  CHAR_LINE_FEED: "\n",

  /* \n */
  CHAR_NO_BREAK_SPACE: "\xA0",

  /* \u00A0 */
  CHAR_PERCENT: "%",

  /* % */
  CHAR_PLUS: "+",

  /* + */
  CHAR_QUESTION_MARK: "?",

  /* ? */
  CHAR_RIGHT_ANGLE_BRACKET: ">",

  /* > */
  CHAR_RIGHT_CURLY_BRACE: "}",

  /* } */
  CHAR_RIGHT_SQUARE_BRACKET: "]",

  /* ] */
  CHAR_SEMICOLON: ";",

  /* ; */
  CHAR_SINGLE_QUOTE: "'",

  /* ' */
  CHAR_SPACE: " ",

  /*   */
  CHAR_TAB: "\t",

  /* \t */
  CHAR_UNDERSCORE: "_",

  /* _ */
  CHAR_VERTICAL_LINE: "|",

  /* | */
  CHAR_ZERO_WIDTH_NOBREAK_SPACE: "\uFEFF"
  /* \uFEFF */

};
export default exports;